import React, { useState } from "react";

import { ApolloProvider } from "@apollo/client";
import { MINUTE_IN_MILLISECONDS } from "@daytrip/constants";
import { GoogleApiProvider } from "@daytrip/react-shared-components";
import { retryQueryLink, shouldRetryQuery } from "@daytrip/trpc-client-utils";
import { ErrorBoundary } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpLink, loggerLink } from "@trpc/client";
import type { Container } from "inversify";
import { IntlProvider } from "react-intl";
import { Router } from "react-router";
import type { Theme, ToastContainerProps } from "react-toastify";
import { ToastContainer } from "react-toastify";
import superjson from "superjson";

import { trpcReact } from "@providers/trpc/trpc-react";

import { getApolloClient } from "./apolloClient/apolloClient";
import { FRESH_URL, GOOGLE_MAPS_API_KEY } from "./config.management";
import { stores } from "./container";
import { AuthenticationStoreProvider } from "./domain/authentication/AuthenticationStore.provider";
import { rootRoute } from "./routes";

const url = FRESH_URL;

const TOAST_OPTIONS: ToastContainerProps = {
    theme: "light" as Theme,
};

const ErrorFallback = ({ error, componentStack }: any) => {
    return (
        <p>
            <pre>{JSON.stringify(error)}</pre>
            Stack:
            <pre>{componentStack}</pre>
        </p>
    );
};

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: shouldRetryQuery({ attempts: 2 /* allows 1 retry for TRPC */ }),
            // staleTime is set to 1 minute to avoid re-fetching data on every component re-render
            staleTime: MINUTE_IN_MILLISECONDS,
            // 5 minutes is a default value for cacheTime, it's just defined explicitly here
            cacheTime: MINUTE_IN_MILLISECONDS * 5,
        },
    },
});

const App = ({ container }: { container: Container }) => {
    const [trpcClient] = useState(() =>
        trpcReact.createClient({
            links: [
                retryQueryLink({
                    attempts: 2, // allow 1 retry
                }),
                loggerLink(),
                httpLink({
                    url, // TODO: Auth cookie
                }),
            ],
            transformer: superjson,
        }),
    );

    const [error, setError] = useState({});
    const onError = (err: Error, componentStack: string) => {
        setError({
            error: err,
            componentStack,
        });
    };

    return (
        <trpcReact.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
                <ApolloProvider client={getApolloClient()}>
                    <AuthenticationStoreProvider>
                        <GoogleApiProvider googleApiKey={GOOGLE_MAPS_API_KEY || ""}>
                            <ErrorBoundary onError={onError} fallback={<ErrorFallback error={error} />}>
                                <IntlProvider locale="en">
                                    <Router
                                        routes={rootRoute}
                                        history={container.get<History>(stores.history) as any}
                                    />
                                    <ToastContainer {...TOAST_OPTIONS} />
                                </IntlProvider>
                            </ErrorBoundary>
                        </GoogleApiProvider>
                    </AuthenticationStoreProvider>
                </ApolloProvider>
            </QueryClientProvider>
        </trpcReact.Provider>
    );
};

export default App;
